import React from 'react'
import './Profile.css'

const Profile = () => {

    return (
            <>
                <div className="profile">
                    <h1 className="about-me">About Me</h1>
                    <img src="./profile-pic.png" className="profilePhoto" alt="Swan Htet Profile"></img>
                    <p className="text1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am Swan, a remote Software Engineer, residing in Chiang Mai, Thailand, orginally from Myanmar(Burma). I studied Software Engineering in Seattle, Washington and
                    currently working for a Singapore based Company. During my free time, I teach myself programming languages
                     and technologies. I love being a Software Engineer and I'm very passionate in Tech.</p>
                </div>
                <hr/>
            </>
    )
}

export default Profile;
