import React from 'react'
import './Footer.css'

const Footer = () => {
    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    return (
        <div className="footerContainer"><hr></hr>
            <div className="footer">
                <p className="domains">
                    <a href="https://swan.asia" target="_blank" rel="noreferrer"> https://swan.asia </a>
                    <a href="https://swan.codes" target="_blank" rel="noreferrer"> https://swan.codes </a>
                    <a href="https://swan.engineer" target="_blank" rel="noreferrer"> https://swan.engineer </a>
                    <a href="https://swanhtet.com" target="_blank" rel="noreferrer"> https://swanhtet.com </a>
                    <a href="https://swanhtet.org" target="_blank" rel="noreferrer"> https://swanhtet.org </a>
                    <a href="https://swanhtet.dev" target="_blank" rel="noreferrer"> https://swanhtet.dev </a>
                </p>
                <p>Swan Htet © 2017 - {currentYear}</p>
            </div>
        </div>
    )
}

export default Footer;
