import React from 'react'
import './Tech.css'


const Tech = () => {

    return (
            <>
                <div className="techPage" >
                    <h1>Tech Stack and Skill Set</h1>
                    <div className="techLogo">
                        <figure className="react">
                            <img src="./images/react.svg" className="logo" alt="logo"></img>
                            <figcaption>React</figcaption>
                        </figure>
                        <figure className="typescript">
                            <img src="./images/typescript.png" className="logo" alt="logo"></img>
                            <figcaption>TypeScript</figcaption>
                        </figure>
                        <figure className="js">
                            <img src="./images/js.png" className="logo" alt="logo"></img>
                            <figcaption>JavaScript</figcaption>
                        </figure>
                        <figure className="node">
                            <img src="./images/node.png" className="logo" alt="logo"></img>
                            <figcaption>NodeJS</figcaption>
                        </figure>
                        <figure className="java">
                            <img src="./images/vue.png" className="logo" alt="logo"></img>
                            <figcaption>Vue</figcaption>
                        </figure>
                        <figure className="html5">
                            <img src="./images/html5.png" className="logo" alt="logo"></img>
                            <figcaption>HTML</figcaption>
                        </figure>
                        <figure className="css">
                            <img src="./images/css.png" className="logo" alt="logo"></img>
                            <figcaption>CSS</figcaption>
                        </figure>
                        <figure className="redux">
                            <img src="./images/redux.png" className="logo" alt="logo"></img>
                            <figcaption>Redux</figcaption>
                        </figure>
			            <figure className="graphql">
                            <img src="./images/graphql.png" className="logo" alt="logo"></img>
                            <figcaption>GraphQL</figcaption>
                        </figure>
			            <figure className="python">
                            <img src="./images/python.png" className="logo" alt="logo"></img>
                            <figcaption>Python</figcaption>
                        </figure>
                        <figure className="django">
                            <img src="./images/django.png" className="logo" alt="logo"></img>
                            <figcaption>Django</figcaption>
                        </figure>
			            <figure className="sass">
                            <img src="./images/sass.svg" className="logo" alt="logo"></img>
                            <figcaption>Sass</figcaption>
                        </figure>
                        <figure className="sql">
                            <img src="./images/sql.png" className="logo" alt="logo"></img>
                            <figcaption>SQL</figcaption>
                        </figure>
                        <figure className="git">
                            <img src="./images/git.png" className="logo" alt="logo"></img>
                            <figcaption>Git</figcaption>
                        </figure>
			            <figure className="github">
                            <img src="./images/github.png" className="logo" alt="logo"></img>
                            <figcaption>GitHub</figcaption>
                        </figure>
                        <figure className="bootstrap">
                            <img src="./images/bootstrap.png" className="logo" alt="logo"></img>
                            <figcaption>Bootstrap</figcaption>
                        </figure>
                        <figure className="postgresql">
                            <img src="./images/postgresql.png" className="logo" alt="logo"></img>
                            <figcaption>PostgreSQL</figcaption>
                        </figure>
                        <figure className="mongodb">
                            <img src="./images/mongodb.png" className="logo" alt="logo"></img>
                            <figcaption>MongoDB</figcaption>
                        </figure>
                        <figure className="docker">
                            <img src="./images/docker.png" className="logo" alt="logo"></img>
                            <figcaption>Docker</figcaption>
                        </figure>
                        <figure className="kubernetes">
                            <img src="./images/kubernetes.png" className="logo" alt="logo"></img>
                            <figcaption>Kubernetes</figcaption>
                        </figure>
                        <figure className="mysql">
                            <img src="./images/mysql.png" className="logo" alt="logo"></img>
                            <figcaption>MySQL</figcaption>
                        </figure>
                        <figure className="aws">
                            <img src="./images/aws.png" className="logo" alt="logo"></img>
                            <figcaption>AWS</figcaption>
                        </figure>
                        <figure className="circleci">
                            <img src="./images/circleci.png" className="logo" alt="logo"></img>
                            <figcaption>CircleCI</figcaption>
                        </figure>
                        <figure className="jenkins">
                            <img src="./images/jenkins.jpeg" className="logo" alt="logo"></img>
                            <figcaption>Jenkins</figcaption>
                        </figure>
                    </div>
                </div>
                <hr/>
            </>
    )
}

export default Tech;
