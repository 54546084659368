import React from 'react'
import Card from './Card.js'
import './Project.css'

const Project = () => {

    return (
        <>
            <div className="project">
                    <h1>Projects (from early 2020)</h1>
                    <div className="card">
                        <Card image={"./images/store.png"} caption1={"./images/laptop.png"} caption2={"./images/code.png"}
                            gitLink={"https://github.com/Swanhtet-grc/Swan-Store"} pictureLink={"https://swan-store.netlify.app/"} />
                        <Card image={"./images/social.png"} caption1={"./images/laptop.png"} caption2={"./images/code.png"}
                        gitLink={"https://github.com/Swanhtet-grc/my-social-app"} pictureLink={"https://swan-social-app.netlify.app/"} />
                        <Card image={"./images/mealfinder.jpg"} caption1={"./images/laptop.png"} caption2={"./images/code.png"}
                            gitLink={"https://github.com/Swanhtet-grc/meal-finder"} pictureLink={"https://swan-meal-finder.netlify.app/"} />
                        <Card image={"./images/calculator.png"} caption1={"./images/laptop.png"} caption2={"./images/code.png"}
                            gitLink={"https://github.com/Swanhtet-grc/my-calculator"} pictureLink={"https://swan-calculator.netlify.app/"} />
                    </div>
            </div>
            <hr/>
        </>
    )
}

export default Project;
